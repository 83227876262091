import { graphql } from "gatsby";
import React, { Fragment } from "react";
import parse from "html-react-parser";

interface Props {
  saveContent: string;
  level: string;
}

export const query = graphql`
  fragment HeadingBlock on WpCoreHeadingBlock {
    saveContent
    attributes {
      ... on WpCoreHeadingBlockAttributes {
        level
      }
    }
  }
`;

const Heading: React.FC<Props> = (props) => {
  const tagName = `h${props.level}`;
  const Tag = tagName as keyof JSX.IntrinsicElements;

  let strippedString = props.saveContent.replace(/(<([^>]+)>)/gi, "");

  return <Tag dangerouslySetInnerHTML={{ __html: strippedString }} />;
};

export default Heading;
