import { graphql } from "gatsby";
import React, { useEffect, useRef } from "react";
import Blocks from "../../components/blocks/Blocks";
import classNames from "classnames";
import SEO from "components/seo/seo";
import { PageContextProps } from "utils/props.utils";
//
import "./page.template.scss";

interface Props {
  pageContext: PageContextProps;
  data: {
    page: {
      id: string;
      title: string;
      blocks: any;
    };
  };
}

const Page: React.FC<Props> = (props) => {
  return (
    <div className="page">
      <SEO {...props.pageContext.seo} />
      <Blocks blocks={props.data.page.blocks} isLoaded={true} />
    </div>
  );
};
export default Page;

export const query = graphql`
  query ($id: String!, $language: String!) {
    page: wpPage(id: { eq: $id }) {
      id
      title 
      blocks {
        name
        order
        ...HeroBlock
        ...ParagraphBlock
        ...HeadingBlock
        ...BookingBlock
        ...GalleryBlock
        ...MenuBlock
        ...ImageBlock
        ...InstafeedBlock
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
