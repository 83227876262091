import { ImgFixedProps } from "components/img/img";
import { graphql, StaticQuery, useStaticQuery } from "gatsby";
import React from "react";
import Helmet from "react-helmet";

export interface SEOProps {
  title: string;
  metaDesc: string;
  opengraphImage: ImgFixedProps;
}

interface Props extends SEOProps {}

const SEO: React.FC<Props> = (props) => {
  const isBrowser = typeof window !== "undefined";
  const url = isBrowser ? window.location : "";

  return (
    <Helmet
      htmlAttributes={{
        lang: "en",
      }}
    >
      <meta charSet="utf-8" />
      <title>{props.title}</title>
      <meta property="og:title" content={props.title} />
      <meta property="og:description" content={props.metaDesc} />
      {!!props.opengraphImage &&
        props.opengraphImage.localFile &&
        props.opengraphImage.localFile.publicURL && (
          <meta
            property="og:image"
            content={props.opengraphImage.localFile.publicURL}
          />
        )}
      <meta property="og:url" content={`${url}`} />
      {/* {process.env.GATSBY_ENV === "PRODUCTION" && (
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=UA-60897812-1"
        ></script>
      )}
      {process.env.GATSBY_ENV === "PRODUCTION" && (
        <script>
          {`window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'UA-60897812-1');`}
        </script>
      )} */}
    </Helmet>
  );
};

export default SEO;
