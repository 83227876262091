import { useLocation } from "@reach/router";
import { graphql } from "gatsby";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
//
import "./booking.scss";

export interface Props {
  iframeUrl: string;
}

const Booking: React.FC<Props> = (props) => {
  const [bookingPage, setBookingPage] = useState<boolean>(false);
  const location = useLocation();

  return (
    <div className={classNames("booking", { "is-booking": bookingPage })}>
      <div className="booking-wrapper">
        <iframe
          src={props.iframeUrl}
          scrolling="no"
          name="opentable-make-reservation-widget"
          title="Online-Reservierungen | OpenTable, frankfurter botschaft"
          width="224"
          height="301"
        ></iframe>
      </div>
    </div>
  );
};

export default Booking;

export const query = graphql`
  fragment BookingBlock on WpAcfBookingBlock {
    AcfBookingBlock {
      iframeUrl
    }
  }
`;
