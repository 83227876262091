import Img, { ImgProps } from "components/img/img";
import { graphql, useStaticQuery } from "gatsby";
import _get from "lodash/get";
import React, { useState } from "react";
//
import "./instafeed.scss";
import InstaFeedItem from "./instafeedItem";

export interface Props {
  images: ImgProps[];
}

const InstaFeed: React.FC<Props> = (props) => {
  return (
    <div className="insta-feed">
      <div className="insta-feed-wrapper">
        <div className="insta-feed-row">
          {!!props.images && props.images
            .reverse()
            .slice(0, 6)
            .map((item: any, key) => {
              return <InstaFeedItem image={item.image} key={key} />;
            })}
        </div>
      </div>
    </div>
  );
};

export default InstaFeed;

export const query = graphql`
  fragment InstafeedBlock on WpAcfInstaFeedBlock {
    AcfInstafeedBlock {
      images {
        image {
          ...ImgMedium
        }
      }
    }
  }
`;
