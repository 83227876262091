import { graphql } from "gatsby";
import React from "react";

export interface ImgProps {
  altText?: string;
  sourceUrl?: string;
  localFile: {
    childImageSharp?: {
      gatsbyImageData: {
        images: {
          fallback: {
            src: string;
            srcSet: string;
            sizes: string;
          };
        };
      };
    };
    publicURL: string;
  };
}

interface Props extends ImgProps {
  className?: string;
}

const Img: React.FC<Props> = (props) => {
  if (!!props.localFile) {
    if (!!props.localFile.childImageSharp) {
      return (
        <img
          src={
            props.localFile.childImageSharp.gatsbyImageData.images.fallback.src
          }
          alt={props.altText}
          className={props.className}
        />
      );
    } else if (!!props.localFile.publicURL) {
      return (
        <img
          src={props.localFile.publicURL}
          alt={props.altText}
          className={props.className}
        />
      );
    }
  }

  return null;
};

export default Img;

export const query = graphql`
  fragment ImgBackground on WpMediaItem {
    altText
    sourceUrl
    localFile {
      publicURL
      childImageSharp {
        gatsbyImageData(width: 1600, quality: 70, formats: [JPG, WEBP, AVIF])
      }
    }
  }
  fragment ImgLarge on WpMediaItem {
    altText
    sourceUrl
    localFile {
      publicURL
      childImageSharp {
        gatsbyImageData(width: 1440, quality: 70, formats: [JPG, WEBP, AVIF])
      }
    }
  }
  fragment ImgMedium on WpMediaItem {
    altText
    sourceUrl
    localFile {
      publicURL
      childImageSharp {
        gatsbyImageData(width: 900, quality: 70, formats: [JPG, WEBP, AVIF])
      }
    }
  }
  fragment ImgSVG on WpMediaItem {
    altText
    sourceUrl
    localFile {
      publicURL
    }
  }
`;
