import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import "./hero.scss";
import Img, { ImgProps } from "components/img/img";
import classNames from "classnames";


interface Props {
  image: ImgProps;
  isFrontPage: boolean;
  
}

const Hero: React.FC<Props> = (props) => {
  const [isScrolled, setIsScrolled] = useState<boolean>(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    }

    handleScroll();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, []);

  return (
    <div className={classNames("hero", { "is-frontpage": props.isFrontPage, "is-scrolled": isScrolled })}>
      <Img {...props.image} className="hero-image" />
      <div className="hero-arrow">
        <div className="hero-arrow__inner"></div>
      </div>
    </div>
  );
};

export default Hero;

export const query = graphql`
  fragment HeroBlock on WpAcfHeroBlock {
    AcfHeroBlock {
      image {
        ...ImgBackground
      }
    }
  }
`;
