import React from "react";
import { graphql } from "gatsby";
import "./image.scss";
import Img, { ImgProps } from "components/img/img";

interface Props {
  image: ImgProps;
}

const Image: React.FC<Props> = (props) => {
  console.log(props);
  return (
    <div className="image">
      <div className="image-wrapper">
        <Img {...props.image} />
      </div>
    </div>
  );
};

export default Image;

export const query = graphql`
  fragment ImageBlock on WpAcfImageBlock {
    AcfImageBlock {
      image {
        ...ImgBackground
      }
    }
  }
`;
