import Img, { ImgProps } from "components/img/img";
import { graphql, useStaticQuery } from "gatsby";
import _get from "lodash/get";
import ImagesLoaded from "react-images-loaded";
import React, { useState } from "react";
//
import "./instafeedItem.scss";
import classNames from "classnames";

export interface Props {
  image: ImgProps;
}

const InstaFeedItem: React.FC<Props> = (props) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const handleLoaded = () => {
    setIsLoaded(true);
  };

  return (
    <div className={classNames("insta-feed-item", { "is-loaded": isLoaded })}>
      <div className="insta-feed-item__inner">
        <ImagesLoaded done={handleLoaded}>
          <Img {...props.image} className="insta-feed-item__img" />
        </ImagesLoaded>
        <a
          href="https://www.instagram.com/frankfurterbotschaft/"
          target="_blank"
          className="insta-feed-item__link"
        ></a>
      </div>
    </div>
  );
};

export default InstaFeedItem;
function props(props: any) {
  throw new Error("Function not implemented.");
}
