import { graphql, useStaticQuery } from "gatsby";

import React from "react";
//
import "./menuUpload.scss";

export interface Props {
  menu: {
    text: string;
    file: {
      localFile: {
        publicURL: string;
      };
    };
  }[];
}

const MenuUpload: React.FC<Props> = (props) => {
  return (
    <div className="menu-upload">
      <div className="menu-upload-wrapper">
        {props.menu.map((item, key) => (
          <div key={key} className="menu-upload__item">
              {!!item.file && (<a href={item.file.localFile.publicURL} target="_blank">{item.text}</a>)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MenuUpload;

export const query = graphql`
  fragment MenuBlock on WpAcfMenuBlock {
    AcfMenuBlock {
      menu {
        text
        file {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`;
