import classNames from "classnames";
import Img, { ImgProps } from "components/img/img";
import { graphql } from "gatsby";
import React, { useEffect, useRef, useState, MouseEvent } from "react";
import Flickity, { FlickityOptions } from "react-flickity-component";

//
import "./gallery.scss";

export interface Props {
  gallery: ImgProps[];
}

const Gallery: React.FC<Props> = (props) => {
  const sliderRef = useRef<any | null>(null);
  const [sliderIndex, setSliderIndex] = useState<number>(0);

  const flickityOptions: FlickityOptions = {
    wrapAround: true,
    prevNextButtons: false,
    pageDots: false,
    initialIndex: 0,
    freeScroll: false,
    autoPlay: 5000,
    cellAlign: "center",
    adaptiveHeight: true,
  };

  useEffect(() => {
    const handleSliderChange = (e: any) => {
      setSliderIndex(e);
    };

    !!sliderRef.current && sliderRef.current.on("change", handleSliderChange);
    return () => {
      !!sliderRef.current &&
        sliderRef.current.off("change", handleSliderChange);
    };
  }, []);

  const handleNumberClick = (e: MouseEvent) => {
    const target = e.currentTarget;
    const index = parseInt(target.getAttribute("data-index") || "");
    !!sliderRef.current && !!sliderRef.current.select(index);
  };

  const handleArrowClick = (e: MouseEvent) => {
    const target = e.currentTarget;
    const direction = parseInt(target.getAttribute("data-dir") || "");

    let newIndex = sliderIndex + direction;
    !!sliderRef.current && !!sliderRef.current.select(newIndex);
  };

  if (!props.gallery) {
    return null;
  }
  return (
    <div className="gallery">
      <div className="gallery-wrapper">
        <Flickity
          options={flickityOptions}
          flickityRef={(ref) => (sliderRef.current = ref)}
          className="gallery-slider"
        >
          {props.gallery.map((item, key) => {
            return (
              <div key={key} className="gallery-slider-item">
                <Img {...item} className="gallery-slider-item__image" />
              </div>
            );
          })}
        </Flickity>
        <div className="gallery-ui">
          <ul className="gallery-numbers">
            {props.gallery.map((n, key) => (
              <li
                className={classNames("gallery-numbers__item", {
                  "is-active": key === sliderIndex,
                })}
                key={key}
                data-index={key}
                onClick={handleNumberClick}
              >
                {key + 1}
              </li>
            ))}
          </ul>
          <div className="gallery-arrows">
            <div
              className="gallery-arrow is-left"
              data-dir="-1"
              onClick={handleArrowClick}
            >
              <span />
            </div>
            <div
              className="gallery-arrow is-right"
              data-dir="1"
              onClick={handleArrowClick}
            >
              <span />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;

export const query = graphql`
  fragment GalleryBlock on WpAcfGalleryBlock {
    AcfGalleryBlock {
      gallery {
        ...ImgLarge
      }
    }
  }
`;
