import Heading from "blocks/core/heading/heading";
import Paragraph from "blocks/core/paragraph/paragraph";
import React from "react";
import Hero from "../../blocks/hero/hero";
import Booking from "blocks/booking/booking";
import Gallery from "blocks/gallery/gallery";
import InstaFeed from "blocks/instafeed/instafeed";
import MenuUpload from "blocks/menuUpload/menuUpload";
import Image from "blocks/image/image";

interface Props {
  blocks: {
    name: string;
    order?: number;
    saveContent?: string;
  }[];
  newsFeeds?: any[];
  isLoaded: boolean;
}

const blockKeys: any = {
  "core/paragraph": "attributes",
  //   "core/freeforms": "attributes",
  "core/heading": "attributes",
  //   "core/pullquote": "attributes",
  //   "core/list": "attributes",
  //   "acf/accordiontext": "AcfAccordionText",
  "acf/hero": "AcfHeroBlock",
  "acf/booking": "AcfBookingBlock",
  "acf/gallery": "AcfGalleryBlock",
  "acf/insta-feed": "AcfInstafeedBlock",
  "acf/menu": "AcfMenuBlock",
  "acf/image": "AcfImageBlock",
};

const Components: any = {
  "acf/booking": Booking,
  "acf/gallery": Gallery,
  "acf/hero": Hero,
  "acf/image": Image,
  "acf/insta-feed": InstaFeed,
  "acf/menu": MenuUpload,
  "core/paragraph": Paragraph,
  "core/heading": Heading,
};

const Blocks: React.FC<Props> = (props) => {
  return (
    <>
      {props.blocks.map((item, key) => {
        const compName: string = item.name;
        // console.log(item)
        if (!Components[compName]) {
          return null;
        }

        const component: React.FC<any> = Components[compName];
        // @ts-ignore
        const data: any = item[blockKeys[compName]];

        console.log(item);
        // add newsfeeds
        return React.createElement(component, {
          ...data,
          saveContent: item.saveContent,
          key: key,
          isLoaded: props.isLoaded,
        });
      })}
    </>
  );
};

export default Blocks;
