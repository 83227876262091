import { graphql } from "gatsby";
import React from "react";
import "./paragraph.scss";

interface Props {
  saveContent: string;
}

export const query = graphql`
  fragment ParagraphBlock on WpCoreParagraphBlock {
    saveContent
  }
`;

const Paragraph: React.FC<Props> = (props) => {
  return <p dangerouslySetInnerHTML={{ __html: props.saveContent }} />;
};

export default Paragraph;
